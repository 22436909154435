// src/utils/notification.js
// 通知工具函數，避免直接在 API 調用中使用 Store

let notificationStore = null

// 初始化通知工具
export function initNotificationUtils (store) {
  notificationStore = store
}

// 顯示成功通知
export function showSuccess (title, message = '', timeout = 3000) {
  if (notificationStore) {
    return notificationStore.showSuccess(title, message, timeout)
  } else {
    console.warn('通知系統尚未初始化')
    console.log('[Success]', title, message)
    return null
  }
}

// 顯示錯誤通知
export function showError (title, message = '', timeout = 5000) {
  if (notificationStore) {
    return notificationStore.showError(title, message, timeout)
  } else {
    console.warn('通知系統尚未初始化')
    console.error('[Error]', title, message)
    return null
  }
}

// 顯示警告通知
export function showWarning (title, message = '', timeout = 4000) {
  if (notificationStore) {
    return notificationStore.showWarning(title, message, timeout)
  } else {
    console.warn('通知系統尚未初始化')
    console.warn('[Warning]', title, message)
    return null
  }
}

// 顯示資訊通知
export function showInfo (title, message = '', timeout = 3000) {
  if (notificationStore) {
    return notificationStore.showInfo(title, message, timeout)
  } else {
    console.warn('通知系統尚未初始化')
    console.info('[Info]', title, message)
    return null
  }
}

// 處理 API 錯誤的通用處理函數
export function handleApiError (error, defaultTitle = '操作失敗', defaultMessage = '請稍後再試') {
  // 確定錯誤標題和訊息
  const title = defaultTitle
  let message = defaultMessage

  if (error) {
    if (error.isApiError) {
      // API 回應的錯誤
      message = error.message || defaultMessage
    } else if (error.isNetworkError) {
      // 網路連線錯誤
      message = '網路連線問題，請檢查您的網路設定'
    } else {
      // 其他錯誤
      message = error.message || defaultMessage
    }
  }

  // 顯示錯誤通知
  showError(title, message)

  // 可選：在開發環境中打印錯誤詳情
  if (process.env.NODE_ENV === 'development') {
    console.error('[API Error]', error)
  }

  return { title, message }
}

// 顯示載入中通知（帶有自動關閉功能）
export function showLoading (message = '載入中...') {
  if (notificationStore) {
    return notificationStore.addNotification({
      type: 'info',
      title: '處理中',
      message,
      timeout: 0, // 不自動關閉
      loading: true // 標記為載入中通知
    })
  } else {
    console.warn('通知系統尚未初始化')
    console.info('[Loading]', message)
    return null
  }
}

// 關閉特定通知
export function closeNotification (id) {
  if (notificationStore && id) {
    notificationStore.removeNotification(id)
  }
}

// 匯出工具函數
export default {
  initNotificationUtils,
  showSuccess,
  showError,
  showWarning,
  showInfo,
  showLoading,
  closeNotification,
  handleApiError
}
