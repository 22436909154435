// src/stores/notificationStore.js
import { defineStore } from 'pinia'
import { ref } from 'vue'
// No imports from other stores to avoid circular dependencies

export const useNotificationStore = defineStore('notificationStore', () => {
  // 狀態
  const notifications = ref([])

  // 產生唯一ID
  function generateId () {
    return Date.now() + Math.floor(Math.random() * 1000)
  }

  // 新增通知
  function addNotification (notification) {
    const id = generateId()
    notifications.value.push({
      id,
      ...notification,
      show: true
    })

    // 自動移除
    if (notification.timeout !== 0) {
      setTimeout(() => {
        removeNotification(id)
      }, notification.timeout || 5000)
    }

    return id
  }

  // 移除通知
  function removeNotification (id) {
    const index = notifications.value.findIndex(n => n.id === id)
    if (index !== -1) {
      notifications.value.splice(index, 1)
    }
  }

  // 顯示成功通知
  function showSuccess (title, message = '') {
    return addNotification({
      type: 'success',
      title,
      message,
      timeout: 3000
    })
  }

  // 顯示錯誤通知
  function showError (title, message = '') {
    return addNotification({
      type: 'error',
      title,
      message,
      timeout: 5000
    })
  }

  // 顯示警告通知
  function showWarning (title, message = '') {
    return addNotification({
      type: 'warning',
      title,
      message,
      timeout: 4000
    })
  }

  // 顯示資訊通知
  function showInfo (title, message = '') {
    return addNotification({
      type: 'info',
      title,
      message,
      timeout: 3000
    })
  }

  return {
    notifications,
    addNotification,
    removeNotification,
    showSuccess,
    showError,
    showWarning,
    showInfo
  }
})
