import axios from 'axios'
// import Swal from 'sweetalert2'
import { createRouter, createWebHistory } from 'vue-router'

/* eslint-disable */
const routes = [
  {
    path: '/',
    redirect: '/dashboard'
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/Login.vue'),
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('../views/Dashboard.vue'),
    children: [
      // 充電椿、充電紀錄
      {
        path: '',
        name: 'charger-overview',
        component: () => import('../views/ChargerOverview.vue')
      },
      {
        path: 'charger-detail',
        name: 'charger-detail',
        component: () => import('../views/ChargerDetail.vue')
      },
      {
        path: 'charging-session',
        name: 'charging-session',
        component: () => import('../views/charging_session/ChargingSessionList.vue')
      },
      {
        path: 'charging-session-bali',
        name: 'charging-session-bali',
        component: () => import('../views/charging_session/ChargingSessionList-BaliKing.vue')
      },
      // 統計頁面
      {
        path: 'monthly',
        name: 'monthly',
        children: [
          {
            path: 'rfid',
            name: 'monthly-rfid',
            component: () => import('../views/monthly/RFID_Monthly.vue')
          },
          {
            path: 'old',
            name: 'monthly-old',
            component: () => import('../views/monthly/old.vue')
          }
        ]
      },
      // 儲值 / 記帳管理
      {
        path: 'deposit',
        name: 'deposit',
        children: [
          {
            path: '',
            name: 'deposit-list',
            component: () => import('../views/deposit/DepositList.vue'),
          }
        ],
      },
      // === 新增: User 管理 ===
      {
        path: 'users-management',
        name: 'users-management',
        component: () => import('../views/users/UserManagement.vue')
      },

      {
        path: 'users-deposit',
        name: 'users-deposit',
        component: () => import('../views/users/UserDeposit.vue')
      },

      // === 新增: 卡片管理 ===
      {
        path: 'cards',
        name: 'card-management',
        component: () => import('../views/cards/CardManagement.vue')
      },
      // 設定
      {
        path: 'settings',
        name: 'settings',
        children: [
          {
            path: 'reset-password',
            name: 'reset-password',
            component: () => import('../views/settings/PasswordReset.vue')
          },
          {
            path: 'fee-configs',
            name: 'fee-configs',
            component: () => import('../views/settings/FeeConfigs.vue')
          }
        ]
      },
    ]
  },
  // Miscs
  {
    path: '/404',
    name: '404',
    component: () => import('../views/404.vue')
  },
  {
    path: '/:pathMatch(.*)',
    redirect: '/404',
  }
]

const router = createRouter({
  base: process.env.BASE_URL,
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return {
      top: 0
    }
  }
})

axios.defaults.xsrfCookieName = 'csrftoken'
axios.defaults.xsrfHeaderName = 'X-CSRFToken'
axios.interceptors.response.use(
  (response) => { return response},  // if status code is 2xx
  (error) => {
    const status_code = error.response.status

    if (status_code == 401 || status_code == 419) {  // if password incorrect or session expire
      router.push({ name: 'login' })
    } else if (status_code == 500) {
      // FIXME location = null
      // 不知什麼時機, 即使登入且有綁定 view device, 前端仍會送出 location = null 的 request

      // Swal.fire({
      //   icon: 'error',
      //   title: '伺服器連線錯誤',
      // })
    }

    return Promise.reject(error)
  }
)

export default router
