import { createApp, markRaw } from 'vue'
import { createPinia } from 'pinia'
import 'bootstrap'
import { currency } from './methods/filters'
import App from './App.vue'
import router from './router'
import pkg from '../package.json'

// 初始化通知工具
import { initNotificationUtils } from './utils/notification'
import { useNotificationStore } from './stores/notificationStore'

import '../node_modules/admin-lte/dist/js/adminlte.min.js'
const { name, version } = pkg

// 設置編譯時特性標誌
window.__VUE_OPTIONS_API__ = true
window.__VUE_PROD_DEVTOOLS__ = false
window.__VUE_PROD_HYDRATION_MISMATCH_DETAILS__ = false

// 初始化應用程式
const app = createApp(App)

// 設定全域過濾器
app.config.globalProperties.$filters = {
  currency
}

// 設定 Pinia
const pinia = createPinia()
pinia.use(({ store }) => {
  store.$router = markRaw(router)
})

// 使用 pinia
app.use(pinia)

// 初始化通知 store
const notificationStore = useNotificationStore()
initNotificationUtils(notificationStore)

// 使用路由 - 確保只初始化一次
app.use(router)

// 全域錯誤處理
app.config.errorHandler = (err, vm, info) => {
  console.error('全域錯誤:', err)
  console.error('錯誤來源:', info)

  // 使用通知系統顯示錯誤
  try {
    const { showError } = require('./utils/notification')
    showError('系統錯誤', '應用程式發生錯誤，請重新載入頁面。')
  } catch (e) {
    console.error('無法顯示錯誤通知:', e)
  }
}

// 掛載應用
app.mount('#app')

console.log(`${name} version: ${version}`)
